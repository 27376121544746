import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent, ConfirmDialogModel, Container, File, FileInput, LoadingService, RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { saveAs } from 'file-saver';
import { KeycloakService } from 'keycloak-angular';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { OrderService } from '../../../order-module/shared/services/order/order.service';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-order-files',
  templateUrl: './order-files.component.html',
  styleUrls: ['./order-files.component.scss']
})
export class OrderFilesComponent extends Container<fromGraphQl.FilesQueryResponse>{

  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad : PdfJsViewerComponent
  currentHeader: string;

  public uploading = false;
  public ownerId: string;
  public value = '';
  public isFile = false;
  public fileName = '';
  public fileApi = environment.fileApi;
  pdfDialogVisible: boolean = false;
  imageDialogVisible: boolean = false;
  currentImage: string;
  public files: any[];

  @Input() set order(orderId: string) {
    this.ownerId = orderId;
    if(this.ownerId){
      this.subscribeToQuery({
        ownerId: this.ownerId,
        sortProperty: 'lastModifiedDate',
        sortDirection: 'desc',
      });
    }
  }
  @Input() header: string;
  @Input() headerColor :string = '#EBEEEF';
  @Output() updateChild = new EventEmitter();
  @Input() style: 'default' | 'overlay' = 'default';

    /**
   * indicates, if the context menu of the action button is visible
   */
    public menuVisible = false;
  constructor(
    apollo: Apollo,
    private http: HttpClient,
    protected keycloakAngular: KeycloakService,
    public dialog: MatDialog,
    public loadingService: LoadingService,
    private router: Router,
    public orderService: OrderService,
    public rolesService: RolesService,
  ) {
    super(apollo, loadingService, fromGraphQl.FILES_QUERY, environment);
  }

  handleSubscriptionResult(data: fromGraphQl.FilesQueryResponse): void {
    this.loadingService.setLoading(false);
    if (data) {
      this.queryRef.stopPolling();
      this.files = data.files;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order) {
      this.ownerId = changes.order.currentValue;
      this.refetchFilesList();
    }
  }

  getItems(file: File) : MenuItem[]{
    let items = [
      {
        label: 'Herunterladen',
        icon: 'pi pi-fw pi-trash', command: () => {
          this.downloadFile(file);
        }
      },
      {
          label: 'Löschen',
          icon: 'pi pi-fw pi-trash', command: () => {
            this.confirmDialog(file);
          }
      }
    ];
    return items;
  }


  refetchFilesList(): void {
    this.queryRef?.refetch({
      ownerId: this.ownerId,
      sortProperty: 'lastModifiedDate',
      sortDirection: 'desc',
    });
  }

  fileChangeEvent(event: any): void {
    if (this.uploading || event.target?.files?.length < 1) {
      return;
    }
    this.loadingService.setLoading(true);
    let extension = event.target?.files[0].type;
    extension = extension.replace(/(.*)\//g, '');
    const uuid = uuidv4();
    this.value = uuid;
    this.uploading = true;
    const params = new HttpParams({
      fromObject: {
        fileName:  event.target?.files[0].name
      }
    });
    const url = environment.fileApi + '/file/upload/?ownerId=' + this.ownerId;
    const formData: FormData = new FormData();
    formData.append('file', event.target?.files[0], this.value + '.' + extension);
    this.http
      .post(url,formData, {params})
      .toPromise()
      .then((data) => {
        console.log('Upload Success', data);
        this.returnUpload();
        this.uploading = false;
      })
      .catch((error) => {
        console.log('Upload Error', error);
        this.returnUpload();
      });
  }

  returnUpload(): void {
    setTimeout(() => {
      this.uploading = false;
      this.refetchFilesList();
      this.updateChild.emit({
        refetch : true
      });
      this.loadingService.setLoading(false);
    }, 1000);
  }

  public downloadFile(file: any): void {
    const downloadName = file?.fileName;
    const url = environment.fileApi + '/file/'+ file?.fullPath;
    this.http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
      //const blob = new Blob([response]);
      saveAs(response, downloadName);
    });
  }

  getFileInput(files: File[]) : FileInput[] {
    let fileInputs = [];
    for (let val of files) {
      fileInputs.push(new FileInput({'id': val?.id}));
    }
    return fileInputs;
  }

  confirmDialog(file: File): void {
    const message = 'Möchtest Du die Datei wirklich löschen?';
    const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.loadingService.setLoading(true);
        this.deleteFile(file.id);
      }
    });
  }

  deleteFile(id: string){
    this.orderService.deleteFile(
      id,
      this.returnCallback(this.router)
    );
  }

  returnCallback(router: Router): void {
    setTimeout(() => {
      this.refetchFilesList();
      this.updateChild.emit({
        refetch : true
      });
      this.loadingService.setLoading(false);
    }, 500);
  }

  getSizeinMb(size: number) {
   return (size /(1024*1024) ).toFixed(2);
  }

  openFile(file: any) {

    console.log(file);

    // open application/pdf, image/jpg, image/jpeg, image/png
    if ( file?.extenstion === 'jpg' || file?.extension === 'jpeg' || file.extension === 'png') {
      console.log('open image');
      this.currentImage = this.fileApi + '/view/'+ file?.fullPath;
      this.currentHeader = file?.fileName;
      this.imageDialogVisible = true;
    } else if (file?.extension === 'pdf'){
      const url = environment.fileApi + '/file/'+ file?.fullPath;
      this.http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
        this.currentHeader = file?.fileName;
        this.pdfDialogVisible = true;
        setTimeout(() => {
          this.pdfViewerAutoLoad.diagnosticLogs = true;
          this.pdfViewerAutoLoad.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
          this.pdfViewerAutoLoad.downloadFileName = file?.fileName;
          this.pdfViewerAutoLoad.openFile = false;
          this.pdfViewerAutoLoad.refresh(); // Ask pdf viewer to load/refresh pdf
        }, 300);
      });
    }

  }

  shouldShowButton(file: any): boolean {
    let _menuItems = this.getItems(file);
    if(_menuItems !== undefined && _menuItems.length > 0){
      return true;
    }
    return false;
  }

  /**
   * triggers, when the context menu will be showm and sets the menuVisible flag to true
   */
  public onMenuShow() {
    this.menuVisible = true;
  }

  /**
   * triggers, when the context menu will be hidden and sets the menuVisible flag to false
   */
  public onMenuHide() {
    this.menuVisible = false;
  }

}
