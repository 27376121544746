import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { User } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { debounceTime, Subject } from 'rxjs';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { Contact } from '../../shared/entities/contact/contact';
import { Task } from '../../shared/entities/task/task';
import { TasksService } from '../../shared/services/tasks/tasks.service';
import { TaskAssignmentDataSource } from '../task-assignment-component/task-assignment.datasource';
import { AbstractAssignmentList } from '../task-assignment-list-unassigned/abstract-task-assignment-list';

@Component({
  selector: 'app-task-assignment-list-completed',
  templateUrl: './task-assignment-list-completed.component.html',
  styleUrls: ['./task-assignment-list-completed.component.scss']
})
export class TaskAssignmentListCompletedComponent extends AbstractAssignmentList implements OnInit, OnChanges {

  // inputs
  @Input() date: Date;
  @Input() locations: LocationEntity[];
  
  dataSource: any;
  loading = false;

  displayedColumns = [
    'dueDateTimePeriod',
    'orderNumber',
    'orderCustomerName',
    'state'
  ];

  // default and current values for sorting and pagination
  sortProperty: string;
  defaultSortProperty: string = 'id';
  sortDirection: string;
  defaultSortDirection: string = 'asc';
  pageNumber: number;
  defaultPageNumber = 0;
  pageSize: number;
  defaultPageSize = 15;
  private textSubject = new Subject<string>();
  text: string;

  // models for multiselect
  public typesModel: any[] = [];
  public sortModel: any;

  // options for multiselect
  public typeOptions: any[];
  
  public states = ['COMPLETED'];
  public types = ['nexcore_rental_resourceAssignmenmt_out', 'nexcore_rental_resourceAssignmenmt_in', 'nexcore_rental_resourceAssignmenmt_refuel'];
  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: "asc"},
    { label: 'Typ absteigend', fieldName: 'type', direction: "desc" },
    { label: 'Erledigt aufsteigend', fieldName: 'completedDateTime', direction: "asc" },
    { label: 'Erledigt absteigend', fieldName: 'completedDateTime', direction: "desc"},
    { label: 'Auftragsnummer aufsteigend', fieldName: 'order.number', direction: "asc" },
    { label: 'Auftragsnummer absteigend', fieldName: 'order.number', direction: "desc"},
    { label: 'Kunde aufsteigend', fieldName: 'customer', direction: "asc" },
    { label: 'Kunde absteigend', fieldName: 'customer', direction: "desc"}
  ];

  constructor(
    private apollo: Apollo,
    public taskService: TasksService,
  ) {
    super('TaskAssignmentListCompletedComponent.unassignedTasksTable', 'session' )
    this.typeOptions = this.getTypeOptions();
    let state = this.loadState();
    this.pageNumber = state?.pageNumber ? state.pageNumber : this.defaultPageNumber;
    this.pageSize = state?.pageSize ? state.pageSize : this.defaultPageSize;
    this.sortProperty = state?.sortProperty ? state.sortProperty : this.defaultSortProperty;
    this.sortDirection = state?.sortDirection ? state.sortDirection : this.defaultSortDirection;
    this.sortModel = this.sortOptions.find(option => option.fieldName === this.sortProperty && option.direction === this.sortDirection);
    this.types = state?.filter.types;
    this.typesModel = this.getTypeOptions().filter(option => this.types?.includes(option.id));
    this.text = state?.filter.text;
    this.textSubject.pipe(
      debounceTime(500)
    ).subscribe(text => {
      this.filter('text', text);
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    // we check here for date and location tho initiate the first load
    // both values must be set, even locations gets an empty array after
    // initilaztion of the input
    if (this.date && this.locations) {
      this.dataSource?.loadTasks({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sortProperty: this.sortProperty,
        sortDirection: this.sortDirection,
        fromCompletedDateTime: this.date?.toISOString(),
        toCompletedDateTime: moment(this.date).add(1, 'days').toDate().toISOString(),
        locationIds: this.locations?.map(location => location.id),
        types: this.types,
        states: this.states,
        text: this.text
      });
    }
  }

  ngOnInit(): void {
    this.dataSource = new TaskAssignmentDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  getTypeName(type: string): string {
    return Task.getTypeLabelFromString(type);
  }

  onSearchInput(text: string) {
    this.textSubject.next(text);
  }

  filter(field: string, value: any) {
    // type
    if (field === 'type') {
      if (value === undefined || value === null) {
        this.typesModel = null;
        this.types = null;
      } else {
        if(value?.length > 0){
          this.types = value?.map(item => item.id);
        } else {
          this.types = undefined;
        }
      }
    }
    // text
    if (field === 'text') {
      this.text = value;
    }
    this.refetchloadTaskList();
  }

  refetchloadTaskList(force = false): void {
    // store new state, then refetch
    this.storeState({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortProperty: this.sortProperty ? this.sortProperty : 'id',
      sortDirection: this.sortDirection ? this.sortDirection : 'asc',
      filter: {
        types: this.types,
        states: this.states,
        text: this.text
      }
    });
    this.dataSource.refetchQuery({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortProperty: this.sortProperty ? this.sortProperty : 'id',
      sortDirection: this.sortDirection ? this.sortDirection : 'asc',
      locationIds: this.locations?.map(location => location.id),
      types: this.types,
      states: this.states,
      text: this.text
    }, force);
  }

  sort(event) {
    if(event?.value?.direction && event?.value?.fieldName){
      this.sortProperty = event.value.fieldName;
      this.sortDirection = event.value.direction;
    } else {
      this.sortProperty = this.defaultSortProperty;
      this.sortDirection = this.defaultSortDirection;
    }
  }

  getTypeOptions(): any[] {
    return [
      { id: 'nexcore_rental_resourceAssignmenmt_out', name: 'Übergabe', label: 'Übergabe' },
      { id: 'nexcore_rental_resourceAssignmenmt_in', name: 'Rücknahme', label: 'Rücknahme' },
      { id: 'nexcore_rental_resourceAssignmenmt_refuel', name: 'Nachtanken', label: 'Nachtanken' }
    ];
  }

  getName(user: User): string {
    if (user?.firstName && user?.lastName) {
      return user.firstName + ' ' + user.lastName;
    } else if (user?.firstName || user?.lastName) {
      return user?.firstName ? user?.firstName : user?.lastName;
    } else {
      return '-'
    }
  }

  public getDataSource() {
    return this.dataSource;
  }

  onPageChange(event) {
    this.pageNumber = event.page;
    this.pageSize = event.rows;
    this.refetchloadTaskList();
  }

}
